<template>
  <div>
    <div>
      <KTPortlet title="View Network Gateways">
        <template slot="body">
          <Loader v-if="gateways === null" :inContent="true">Loading...</Loader>
          <b-table-simple v-else responsive>
            <b-thead>
              <b-tr>
                <b-th sticky-column>Name</b-th>
                <b-th>Status</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="gateway in gateways" v-bind:key="gateway.id">
                <b-th sticky-column>{{ gateway.name }}</b-th>
                <b-td>
                  <v-chip
                    class="ma-2"
                    :style="
                      `background: var(--${
                        gateway.status ? 'success' : 'danger'
                      }); color: #fff;`
                    "
                    >{{ gateway.status ? "ONLINE" : "OFFLINE" }}</v-chip
                  >
                </b-td>
                <b-td>
                  <router-link :to="`/Networks/Gateways/${gateway.id}`">
                    <b-button variant="info">View/Edit</b-button>
                  </router-link>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
import TLib from "./lib";

export default {
  components: {
    KTPortlet,
    Loader
  },
  watch: {},
  data() {
    return {
      showTreeView: false,
      currentPage: 1,
      perPage: 50,
      gateways: null,
      types: null
    };
  },
  methods: {
    GetTemplate(templateId) {
      if (this.$tools.isNullOrUndefined(templateId)) return null;
      for (let tTemplate of this.templates)
        if (tTemplate.id === templateId) return tTemplate;

      return null;
    },
    GetRouterType(typeId) {
      for (let tType of this.types) if (tType.id === typeId) return tType;

      return null;
    },
    GetMutatedChildren(thisId) {
      let list = [];
      for (let tTemplate of this.templates)
        if (tTemplate.parentTemplateId === thisId) list.push(tTemplate);
      return list;
    },
    GetMutatedObject(thisId) {
      let children = this.GetMutatedChildren(thisId);
      for (let i = 0; i < children.length; i++) {
        let iChildren = this.GetMutatedObject(children[i].id);
        if (iChildren.length > 0) children[i].children = iChildren;
      }
      return children;
    },
    updateData() {
      let self = this;
      self.$data.gateways = null;
      TLib.getTypes(self, self.$route.params.serviceId)
        .then(typex => {
          self.$data.types = typex;
          TLib.getGateways(self, self.$route.params.serviceId)
            .then(data => {
              self.$data.gateways = data;
            })
            .catch(x => {
              self.$log.error(x);
              //console.error("Error loading content");
            });
        })
        .catch(x => {
          self.$log.error(x);
          //console.error("Error loading content");
        });
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    mutatedTreeViewOfTemplates() {
      return this.GetMutatedObject(null);
    }
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    this.$eventBus.off("networks-gateway-status");
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Networks" },
      { title: "Gateways" }
    ]);
    let self = this;

    this.updateData();
    this.$eventBus.on(`switch-service-selected`, () => self.updateData());
    this.$eventBus.on("networks-gateway-status", status => {
      if (self.$tools.isNullOrUndefined(self.gateways)) return;

      for (let i = 0; i < self.gateways.length; i++) {
        if (self.gateways[i].id === status.gatewayId) {
          self.gateways[i].status = status.status;
          self.gateways[i].lastMessage = status.time;
          return;
        }
      }
    });
  }
};
</script>
